<template>
  <div class="wrap">
    <NavBar :title="$t('newAdd.Recharge')" />
    <!-- <NavBar :title="$t('rechargeDesc.nav')" /> -->
    <div v-for="item,index in 4" :key="'p'+index" class="content_p">
      <p>{{$t("rechargeDesc.p"+item)}}</p>
      <br>
    </div>
    <!-- <Tabbar active="RechargeDesc" /> -->
  </div>
</template>

<script>
import Tabbar from '@/components/Tabbar.vue';
import { NavBar } from 'vant';
export default {
  components: { Tabbar, NavBar },
  data(){
    return{

    }
  }
}
</script>

<style lang="less" scoped>
.wrap{
  min-height: 100vh;
  background: #f5f5f5f5;
  .content_p{
    padding: 0 12px;
    &:nth-child(1){
      padding-top: 12px;
    }
  }
}
</style>